
export default {
  name: 'index',
  data: () => ({
    localQuestions: [],
  }),
  props: {
    questions: {
      type: Array,
      default: [],
    },
    isVisibleTitle: {
      type: Boolean,
      default: true,
    },
    isFormatsFaq: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.localQuestions = JSON.parse(JSON.stringify(this.questions))
  },
}
